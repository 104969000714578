<template>
  <div class="card">
    <TabMenu :model="items" :activeIndex="activeIndex" />
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          label: 'Contracheques',
          icon: 'pi pi-fw pi-wallet',
          to: { name: 'ContraChequeLista' },
        },
        {
          label: 'Declaração de Rendimentos',
          icon: 'pi pi-fw pi-money-bill',
          to: { name: 'rendimentos' },
        },
      ],
      activeIndex: 0,
      tabIndex: {
        contrachequeList: 0,
        rendimentos: 1,
      },
    }
  },

  mounted() {
    this.activeIndex = this.tabIndex[this.$route.name]
  },

  updated() {
    this.activeIndex = this.tabIndex[this.$route.name]
  },
}
</script>
<style></style>
